import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  facebookURL?: string
  instagramURL?: string
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  phone?: string
  siteName?: string
  vat?: string
  whatsappURL?: string
}

export const Footer = memo(function Footer({
  address,
  facebookURL,
  instagramURL,
  languageCode,
  languagePrefix,
  logo,
  phone,
  siteName,
  vat,
  whatsappURL,
}: Props) {
  return (
    <Container>
      {logo ? <Logo src={logo} alt={siteName} width="166" height="98" /> : null}
      {address ? <Address>{address}</Address> : null}
      {phone ? (
        <Phone
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Mobile Phone',
                })
            }
          }}
        >
          {phone}
        </Phone>
      ) : null}
      {vat ? (
        <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
      ) : null}
      <Social dial={5} row>
        {facebookURL ? (
          <SocialItem
            aria-label="Facebook"
            href={facebookURL}
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="20"
              viewBox="0 0 10 20"
            >
              <path
                d="M134.25,6.875v-2.5a1.25,1.25,0,0,1,1.25-1.25h1.25V0h-2.5a3.75,3.75,0,0,0-3.75,3.75V6.875H128V10h2.5V20h3.75V10h2.5L138,6.875Z"
                transform="translate(-128)"
              />
            </svg>
          </SocialItem>
        ) : null}
        {instagramURL ? (
          <SocialItem
            aria-label="Instagram"
            href={instagramURL}
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.997"
              height="20"
              viewBox="0 0 19.997 20"
            >
              <path d="M5.876,19.941a7.362,7.362,0,0,1-2.425-.465A5.131,5.131,0,0,1,.524,16.55a7.343,7.343,0,0,1-.465-2.426C.013,13.057,0,12.717,0,10S.013,6.946.063,5.876A7.337,7.337,0,0,1,.527,3.45,4.938,4.938,0,0,1,1.681,1.68,4.871,4.871,0,0,1,3.454.522,7.314,7.314,0,0,1,5.882.059C6.947.011,7.288,0,10,0s3.055.011,4.125.062a7.319,7.319,0,0,1,2.428.465,5.117,5.117,0,0,1,2.926,2.927,7.326,7.326,0,0,1,.465,2.425C19.994,6.946,20,7.285,20,10s-.011,3.055-.059,4.122a7.313,7.313,0,0,1-.465,2.426,5.114,5.114,0,0,1-2.927,2.926,7.324,7.324,0,0,1-2.425.465C13.054,19.987,12.715,20,10,20S6.944,19.987,5.876,19.941ZM5.971,1.875a5.488,5.488,0,0,0-1.856.344,3.106,3.106,0,0,0-1.149.746,3.022,3.022,0,0,0-.751,1.149A5.49,5.49,0,0,0,1.872,5.97c-.047,1.051-.059,1.371-.059,4.04s.011,2.985.059,4.04a5.515,5.515,0,0,0,.343,1.855,3.126,3.126,0,0,0,.746,1.148,3.092,3.092,0,0,0,1.15.746,5.491,5.491,0,0,0,1.856.343c1.051.047,1.371.059,4.04.059s2.985-.011,4.04-.059A5.507,5.507,0,0,0,15.9,17.8a3.321,3.321,0,0,0,1.9-1.9,5.472,5.472,0,0,0,.343-1.855c.047-1.055.059-1.368.059-4.036s-.011-2.985-.059-4.04A5.5,5.5,0,0,0,17.8,4.114a3.1,3.1,0,0,0-.748-1.149,3.037,3.037,0,0,0-1.148-.746,5.468,5.468,0,0,0-1.856-.344c-1.051-.047-1.367-.059-4.04-.059S7.025,1.828,5.971,1.875ZM4.866,10A5.137,5.137,0,1,1,10,15.14,5.138,5.138,0,0,1,4.866,10Zm1.8,0A3.333,3.333,0,1,0,10,6.669,3.333,3.333,0,0,0,6.67,10Zm7.474-5.34a1.2,1.2,0,1,1,1.2,1.2A1.2,1.2,0,0,1,14.144,4.661Z" />
            </svg>
          </SocialItem>
        ) : null}
        {whatsappURL ? (
          <SocialItem
            aria-label="Whatsapp"
            href={whatsappURL}
            rel="noreferrer"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.905"
              height="20"
              viewBox="0 0 19.905 20"
            >
              <path d="M17.062,2.907a9.913,9.913,0,0,0-15.6,11.957L.057,20l5.254-1.378A9.914,9.914,0,0,0,17.062,2.907ZM10.048,18.154a8.311,8.311,0,0,1-4.493-1.327l-3.118.817.833-3.04a8.244,8.244,0,1,1,6.779,3.549Z" />
              <path
                d="M15.56,13.052c-.248-.124-1.465-.723-1.693-.806s-.392-.124-.557.124-.64.806-.784.971-.289.186-.537.062A6.759,6.759,0,0,1,10,12.173,7.455,7.455,0,0,1,8.62,10.457c-.144-.247-.015-.382.108-.505s.247-.289.372-.434a1.624,1.624,0,0,0,.25-.413.455.455,0,0,0-.021-.434c-.063-.123-.558-1.343-.763-1.838s-.406-.417-.557-.425L7.534,6.4a.907.907,0,0,0-.66.31,2.779,2.779,0,0,0-.867,2.066,4.819,4.819,0,0,0,1.011,2.562,11.041,11.041,0,0,0,4.23,3.739,14.329,14.329,0,0,0,1.412.522,3.4,3.4,0,0,0,1.56.1,2.551,2.551,0,0,0,1.672-1.177,2.063,2.063,0,0,0,.144-1.178C15.974,13.237,15.809,13.176,15.56,13.052Z"
                transform="translate(-0.992 -1.067)"
              />
            </svg>
          </SocialItem>
        ) : null}
      </Social>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  letter-spacing: 0.0175rem;
  line-height: 1.25rem;
  padding: 3.75rem 10vw 1.875rem;
  text-align: center;
`

const Logo = styled.img`
  margin-bottom: 1.9375rem;
`

const Address = styled.div``

const Phone = styled.a`
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`

const Vat = styled.div`
  margin-top: 1.5625rem;
`

const Social = styled(FlexBox)`
  margin-top: 3.75rem;
`

const SocialItem = styled.a`
  margin: 0 0.625rem;
  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark1};
    &:hover {
      fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }
`
