import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

import { Info } from './Info'

export interface Props {
  benefitsList?: string[]
  benefitsTitle?: string
  claim: string
  image?: ImageProps
  logo?: string
  priceLabel?: string
  priceTitle?: string
  priceValue?: string
  siteName: string
  validityTitle?: string
  validityValue?: string
}

export const Hero = memo(function Hero({
  benefitsList,
  benefitsTitle,
  claim,
  image,
  logo,
  priceLabel,
  priceTitle,
  priceValue,
  siteName,
  validityTitle,
  validityValue,
}: Props) {
  return (
    <Container>
      <Wrapper className={!priceValue || !benefitsTitle ? 'full' : undefined}>
        {logo ? (
          <Logo
            className="logo"
            src={logo}
            alt={siteName}
            width="234"
            height="142"
          />
        ) : null}
        {image ? <Background {...image} /> : null}
        {claim ? <Claim>{claim}</Claim> : null}
      </Wrapper>
      <Info
        benefitsList={benefitsList}
        benefitsTitle={benefitsTitle}
        priceLabel={priceLabel}
        priceTitle={priceTitle}
        priceValue={priceValue}
        validityTitle={validityTitle}
        validityValue={validityValue}
      />
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled.div`
  height: calc(100vh - 8.875rem);
  max-height: -webkit-fill-available;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:before {
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.42)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
  }
  &:after {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }
  &.full {
    height: 100vh !important;
  }

  @media (max-width: 1199px) {
    height: calc(100vh - 6rem);
  }

  @media (max-width: 767px) {
    height: calc(100vh - 10.125rem);
  }
`

const Logo = styled.img`
  width: auto;
  margin: auto;
  position: absolute;
  top: 3.75rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media (max-width: 1199px) {
    height: 6.25rem;
    top: 3rem;
  }
`

const Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 200;
  line-height: 6rem;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
  text-align: center;
  transform: translateY(-50%);
  z-index: 3;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
    right: 8.454vw;
    left: 8.454vw;
  }
`
