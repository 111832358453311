import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { FormData, LandingFormSenderBackend } from 'app/utils/LandingFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  landingTitle?: string
  languageCode: string
  onLandingFormSendToBackend: LandingFormSenderBackend
  title?: string
}

export const LandingForm = memo(function LandingForm({
  description,
  landingTitle,
  languageCode,
  onLandingFormSendToBackend,
  title,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onLandingFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)
        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Landing Quotation Form',
          })

        const script = document.createElement('script')
        script.innerHTML = `fbq('track', 'Lead');`
        document.body.appendChild(script)
      }
    },
    [isSendingForm, onLandingFormSendToBackend],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      landing_title: landingTitle,
    },
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */
  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container id="section-form" row wrap stretch tag="section">
      <LeftSide>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>
      <RightSide>
        <Form
          onSubmit={isSendingForm ? undefined : onSubmit}
          noValidate
          autoComplete={'off'}
        >
          <Spinner className={isSendingForm ? '' : 'hidden'} variant="dark" />
          <Wrapper row wrap space="between">
            <Field>
              <Input
                error={formState.errors.name !== undefined}
                isDirty={formState.dirtyFields.name}
                placeholder={`${useVocabularyData('name', languageCode)} *`}
                type="text"
                required={true}
                {...register('name', {
                  required: true,
                  validate: (name) => name.length > 2,
                })}
              />
            </Field>
            <Field>
              <Input
                error={formState.errors.lastname !== undefined}
                placeholder={`${useVocabularyData('lastname', languageCode)} *`}
                type="text"
                required={true}
                {...register('lastname', {
                  required: true,
                  validate: (lastname) => lastname.length > 2,
                })}
              />
            </Field>
            <Field>
              <Input
                error={formState.errors.email !== undefined}
                isDirty={formState.dirtyFields.email}
                placeholder={`${useVocabularyData('email', languageCode)} *`}
                type="email"
                required={true}
                {...register('email', {
                  required: true,
                  validate: (email) => EMAIL_REGEX.test(email),
                })}
              />
            </Field>
            <Field className="full-width">
              <Textarea
                placeholder={useVocabularyData('message', languageCode)}
                required={false}
                {...register('message')}
              />
            </Field>
            <Input type="hidden" {...register('landing_title')} />
          </Wrapper>
          <Checkboxes row wrap>
            <Checkbox
              error={formState.errors.privacy_policy !== undefined}
              label={useVocabularyData('privacy-policy-text', languageCode)}
              required={true}
              {...register('privacy_policy', {
                required: true,
              })}
            />
            <Checkbox
              error={formState.errors.newsletter_subscription !== undefined}
              label={useVocabularyData(
                'newsletter-subscription-text',
                languageCode,
              )}
              {...register('newsletter_subscription')}
            />
          </Checkboxes>
          {displaySendErrorFeedback ? (
            <FormMessages
              text={useVocabularyData('form-error-message', languageCode)}
              title={useVocabularyData(
                'form-error-message-title',
                languageCode,
              )}
              type="error"
            />
          ) : null}
          {displaySendConfirmFeedback ? (
            <FormMessages
              text={useVocabularyData(
                'form-confirmation-message',
                languageCode,
              )}
              title={useVocabularyData(
                'form-confirmation-message-title',
                languageCode,
              )}
            />
          ) : null}
          <Input
            name="submit"
            type="submit"
            value={useVocabularyData('send-request', languageCode)}
            variant="submit"
          />
        </Form>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`

const LeftSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  padding: 5.25rem 6.597vw 6rem 10vw;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.5rem 5.625rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.5rem;
  }
`

const RightSide = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  width: 50%;
  padding: 3.75rem 10vw 6rem 0;

  @media (max-width: 1199px) {
    padding: 2.25rem 1.5rem 5.625rem 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.5rem 3rem;
  }

  .fresnel-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`

const Form = styled.form`
  width: 100%;
  position: relative;

  input[type='submit'] {
    max-width: none;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 200;
  letter-spacing: 0.3125rem;
  line-height: 3.75rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 2.5rem;
    letter-spacing: 0.25rem;
    line-height: 3rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  margin-top: 1.5rem;
`

const Wrapper = styled(FlexBox)``

const Field = styled.div`
  width: calc(50% - 1.5rem);
  margin-top: 1.5rem;
  &.full-width {
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Checkboxes = styled(FlexBox)`
  > div {
    width: 50%;
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 767px) {
    margin-top: 2.25rem;
    > div {
      width: 100%;
    }
  }
`
