import styled from '@emotion/styled'
import { Cards } from 'app/components/Landing/Cards'
import { Footer } from 'app/components/Landing/Footer'
import { Hero } from 'app/components/Landing/Hero'
import { Paragraph } from 'app/components/Landing/Paragraph'
import { ZigZag } from 'app/components/Landing/ZigZag'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { LandingForm } from 'app/containers/LandingForm'
import {
  LandingFormSenderBackend,
  LandingFormSenderBackendConfiguration,
} from 'app/utils/LandingFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useLayoutEffect, useMemo } from 'react'
import { hotjar } from 'react-hotjar'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  structuredDataProps?: StructuredDataProps
  landingFormSenderBackendConfiguration: LandingFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function LandingPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      LandingFormSenderBackend(
        pageContext.landingFormSenderBackendConfiguration,
      ),
    [pageContext.landingFormSenderBackendConfiguration],
  )

  useLayoutEffect(() => {
    hotjar.initialize(2946007, 6)
  }, [])

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.zigZagProps ? <ZigZag {...context.zigZagProps} /> : null}
      {context.paragraphProps ? (
        <Paragraph {...context.paragraphProps} />
      ) : null}
      {context.cardsProps ? <Cards {...context.cardsProps} /> : null}
      <LandingForm
        {...(context.landingFormProps ? context.landingFormProps : null)}
        onLandingFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
      />
      {context.footerProps ? <Footer {...context.footerProps} /> : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
