import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowDown, Clock, Tag } from 'react-feather'

import { Benefit } from './Benefit'

export interface Props {
  benefitsList?: string[]
  benefitsTitle?: string
  priceLabel?: string
  priceTitle?: string
  priceValue?: string
  validityTitle?: string
  validityValue?: string
}

export const Info = memo(function Info({
  benefitsList,
  benefitsTitle,
  priceLabel,
  priceTitle,
  priceValue,
  validityTitle,
  validityValue,
}: Props) {
  const onClick = () => {
    window.scroll({
      top: window.innerHeight + 1,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      <Wrapper dial={4} row wrap stretch>
        {priceValue ? (
          <Cell dial={4} row>
            <Tag />
            <Wrap dial={7} row wrap>
              {priceTitle ? <Title>{priceTitle}</Title> : null}
              {priceValue ? <Value>{priceValue}</Value> : null}
              {priceLabel ? <Label>{priceLabel}</Label> : null}
            </Wrap>
          </Cell>
        ) : null}
        {validityValue ? (
          <Cell dial={4} row>
            <Clock />
            <Wrap dial={7} row wrap>
              {validityTitle ? <Title>{validityTitle}</Title> : null}
              {validityValue ? <Subtitle>{validityValue}</Subtitle> : null}
            </Wrap>
          </Cell>
        ) : null}
        {benefitsTitle ? (
          <Benefits dial={4} row space="between">
            <BenefitsTitle>{benefitsTitle}</BenefitsTitle>
            <ButtonScroll dial={5} onClick={onClick}>
              <ArrowDown />
            </ButtonScroll>
          </Benefits>
        ) : null}
      </Wrapper>
      {benefitsList ? (
        <BenefitsList dial={4} row wrap>
          {benefitsList.map((item, index) => (
            <Benefit
              key={index}
              label={
                // @ts-ignore
                item.label
              }
            />
          ))}
        </BenefitsList>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
`

const Wrapper = styled(FlexBox)`
  padding-left: 10vw;

  @media (max-width: 1199px) {
    padding-left: 0;
  }
`

const Cell = styled(FlexBox)`
  width: 25%;
  padding: 2.5rem 0;

  svg {
    width: auto;
    height: 3rem;
    margin-right: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
    stroke-width: 1;
  }

  @media (max-width: 1199px) {
    width: 50%;
    padding: 1.5rem;
    &:first-of-type {
      justify-content: flex-end;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    align-items: center;
    text-align: center;
    &:first-of-type {
      justify-content: center;
      padding-bottom: 0;
    }

    svg {
      display: none;
    }
  }
`

const Wrap = styled(FlexBox)`
  @media (max-width: 767px) {
    display: block;
    margin: auto;
  }
`

const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Value = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;

  @media (max-width: 1199px) {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }

  @media (max-width: 767px) {
    display: inline;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 0.9375rem;
  line-height: 1.125rem;
  margin-left: 0.4375rem;
  transform: translateY(-5px);

  @media (max-width: 767px) {
    display: inline;
  }
`

const Benefits = styled(FlexBox)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 0 10vw 0 5.139vw;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 1.5rem;
  }
`

const BenefitsTitle = styled.div`
  width: calc(100% - 5.25rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 1.375rem;
  line-height: 1.875rem;
  padding-right: 2.25rem;

  @media (max-width: 1199px) {
    width: 100%;
    padding: 3rem 1.5rem 0;
    text-align: center;
  }
`

const ButtonScroll = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 2px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  z-index: 3;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.3)};
    border-color: transparent;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const BenefitsList = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 0 10vw 3.3125rem;

  @media (max-width: 1199px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
`
