import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  priceLabel?: string
  priceTitle?: string
  priceValue?: string
  subtitle?: string
  title?: string
}

export const Card = memo(function Card({
  description,
  image,
  priceLabel,
  priceTitle,
  priceValue,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {priceValue ? (
          <Price className="card-price">
            {priceTitle ? <PriceTitle>{priceTitle}</PriceTitle> : null}
            <PriceValue>{priceValue}</PriceValue>
            {priceLabel ? <PriceLabel>{priceLabel}</PriceLabel> : null}
          </Price>
        ) : null}
        {image ? <LazyLoadImage className="card-img" {...image} /> : null}
        {title ? <Title>{title}</Title> : null}
      </Wrapper>
      {description || subtitle ? (
        <Text className="card-text">
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </Text>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  cursor: pointer;
  &:hover {
    .card-price {
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
    .card-img {
      transform: scale(1);
    }
    .card-text {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const Wrapper = styled.div`
  height: 53.5vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark0, 0)},
      ${rgba(theme.colors.variants.neutralDark0, 0.7)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.1);
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1199px) {
    img {
      transform: scale(1);
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 200;
  letter-spacing: 0.1875rem;
  line-height: 2.25rem;
  position: absolute;
  bottom: 1.875rem;
  left: 2.1875rem;
  text-transform: uppercase;
  z-index: 3;
`

const Price = styled.div`
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.1)};
  border-top: 0;
  border-right: 0;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  padding: 1rem 1.5625rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  z-index: 3;
`

const PriceTitle = styled.div`
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.875rem;
`

const PriceValue = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.375rem;
`

const PriceLabel = styled.div`
  font-size: 0.9375rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.875rem;
  opacity: 0.4;
`

const Text = styled.div`
  opacity: 0;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  text-align: center;
  transform: translateY(1.5rem);
  transition: 0.3s ease-in-out;

  @media (max-width: 1199px) {
    opacity: 1;
    transform: translateY(0);
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  margin-bottom: 0.875rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
`
