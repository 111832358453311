import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { Check } from 'react-feather'

export interface Props {
  label: string
}

export const Benefit = memo(function Benefit({ label }: Props) {
  if (!label) {
    return null
  }

  return (
    <Container dial={4} row>
      <Icon dial={5}>
        <Check />
      </Icon>
      <Label>{label}</Label>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 25%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 0.9375rem;
  letter-spacing: 0.0437rem;
  line-height: 1.25rem;
  margin-top: 3.3125rem;
  padding-right: 4.167vw;

  @media (max-width: 1023px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0;
  }
`

const Icon = styled(FlexBox)`
  width: 2.25rem;
  height: 2.25rem;
  background: ${rgba(theme.colors.variants.neutralLight4, 0.16)};
  border-radius: 50%;
  margin-right: 0.75rem;

  svg {
    width: auto;
    height: 1rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Label = styled.div`
  width: calc(100% - 3rem);
`
