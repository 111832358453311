import styled from '@emotion/styled'
import { uniq } from 'lodash'
import React, { memo } from 'react'

import { Props as StripeProps, Stripe } from './Stripe'

export interface Props {
  stripes: StripeProps[]
}

export const ZigZag = memo(function ZigZag({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      {uniq(stripes).map((item, index) => (
        <Stripe key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding-top: 9rem;

  @media (max-width: 767px) {
    padding-top: 0;
  }
`
