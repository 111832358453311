import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'

export interface Props {
  buttonLabel?: string
  list: string[]
  title?: string
}

export const Paragraph = memo(function Paragraph({
  buttonLabel,
  list,
  title,
}: Props) {
  if (!list) {
    return null
  }

  if (list.length < 1) {
    return null
  }

  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {title ? (
          <Fade bottom distance="3.75rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {buttonLabel ? (
          <Fade bottom>
            <CTA to="section-form" smooth>
              <CTALabel>{buttonLabel}</CTALabel>
            </CTA>
          </Fade>
        ) : null}
      </LeftSide>
      <RightSide>
        {list.map((item, index) => (
          <Fade key={index} bottom distance="3.75rem">
            <Check>
              {
                // @ts-ignore
                item.label
              }
            </Check>
          </Fade>
        ))}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 0 10vw 12.5rem;

  @media (max-width: 1199px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 767px) {
    padding-bottom: 7.5rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding-right: 8.819vw;

  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3rem;

  @media (max-width: 767px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin-bottom: 1.875rem;
  }
`

const CTA = styled(Link)`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  overflow: hidden;
  margin-top: 3rem;
  padding: 1.1875rem 1.875rem;
  position: relative;
  &:hover {
    &:after {
      top: 0;
    }
  }
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    top: 100%;
    left: 0;
    transition: 0.3s ease-out;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const CTALabel = styled.span`
  position: relative;
  z-index: 2;
`

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Check = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 1rem;
  padding-left: 1rem;
  position: relative;
  &:before {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    border-radius: 50%;
    position: absolute;
    top: 0.5rem;
    left: 0;
  }
`
