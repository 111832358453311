import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Card, Props as CardProps } from './Card'

export interface Props {
  cards: CardProps[]
  title?: string
}

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export const Cards = memo(function Cards({ cards, title }: Props) {
  if (cards.length < 1) {
    return null
  }

  const settings = {
    infinite: cards.length > 3 ? true : false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: cards.length > 2 ? 3 : cards.length,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  }

  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      <Slider {...settings}>
        {uniq(cards).map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </Slider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 0 10vw 6.25rem;

  .slick-slider {
    margin: 3.75rem -1.875rem 0;
  }
  .slick-track {
    display: flex;
    margin-left: 0;
  }
  .slick-slide {
    padding: 0 1.875rem;
  }

  @media (max-width: 1199px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    .slick-slider {
      margin: 3rem -0.9375rem 0;
    }
    .slick-slide {
      padding: 0 0.75rem;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 200;
  letter-spacing: 0.3125rem;
  line-height: 3.75rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 5rem);
  transition: 0.3s ease-in-out;
  transform: translateY(-50%);
  z-index: 2;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.slider-prev {
    left: -0.75rem;
  }
  &.slider-next {
    right: -0.75rem;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
